import React, {Component} from 'react';
import style from './gallerySlider.module.scss';
import Slider from 'react-slick';
import ReactDOM from 'react-dom';
import Background from 'components/background';
import Link from 'components/link';

class GallerySlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIndex: 0,
			activeIndex: 0
		}
	}
	componentDidMount() {
		this.node = ReactDOM.findDOMNode(this);
	}
	render() {
		const currentIndex = this.state.currentIndex;
		const data = this.props.data[currentIndex].node;
		const images = data.galleryImages;
		const slick = {
			dots: false,
			asNavFor: this.state.nav2,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			className: style.slick,
			arrows: false,
			ref: slider => (this.slider = slider),
			afterChange: this.handleChange,
			fade: true
		}

		const slick2 = {
			dots: true,
			arrows: false,
			infinite: false,
			rows: 3,
			slidesPerRow: 3,
			slidesToShow: 1,
			dotsClass: `${style.dots}`,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						rows: 1,
					}
				},
			]
		}

		return (
			<div className={`grid-container ${style.galleryContainer}`}>
				<div className="grid-x ">
					<div className={`${style.imageCell} cell small-12 medium-12 ${this.props.left ? "large-order-2" : ""} large-8`}>
						{ data.key !== "more-galleries" ?
							<Slider {...slick}>
								{images.map((image, index) => (
									<div key={index} className={style.slide}>
										<Background className={style.background} image={image} />
									</div>
								))}
							</Slider> :
							<div className={style.slide}>
								<Background className={style.background} image="" />
							</div>
						}
					</div>
					<div className={`${style.floater} cell small-12 medium-12 ${this.props.left ? "large-order-1" : ""} large-4`}>
						<div className={style.title}>{data.title}</div>
						{/*<div className={style.arrows}>
							<span onClick={() => this.setState({currentIndex: currentIndex + 1})}>Next</span>
						</div>*/}
						{ data.key !== "more-galleries" ?
							<Slider {...slick2}>
								{images.map((image, index) => (
									<div key={index} className={`cell ${style.slideThumb} ${this.state.activeIndex === index ? style.isActive : ""}`}>
										<div role="presentation" onClick={() => {
											this.slider.slickGoTo(index)
											this.setState({ activeIndex: index })
										}} className={style.thumbInner}>
											<Background className={style.backgroundThumb} image={image} />
										</div>
									</div>
									))}
							</Slider> :
							<div className={`${style.thumbnails} grid-x medium-up-1`}>
								{data.links.map((link, index) => (
									<div key={index} className={`cell`}>
										<Link className={style.link} to={link.href}>{link.label}</Link>
									</div>
								))}
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default GallerySlider;