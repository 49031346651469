import React, { Component } from "react";
import Layout from "templates/basic/";
import Intro from 'components/intro';
import SEO from 'components/seo';
import style from 'templates/StaffTeamAll/StaffTeamAll.module.scss';
import Icon from 'components/icon';
import StaffGrid from 'components/staffGrid';
import Accordion from 'components/accordion';
import GallerySlider from 'components/gallerySlider';


export default class StaffTeamAll extends Component {
	constructor(props) {
		super(props);
		this.page = null;
		if (props.data.allContentPage && props.data.allContentPage.edges.length) this.page = props.data.allContentPage.edges[0].node;
		this.teams = null;
		if (props.data.allStaffTeam && props.data.allStaffTeam.edges.length) this.teams = props.data.allStaffTeam.edges;
		this.awards = null;
		if (props.data.allStaffAward && props.data.allStaffAward.edges.length) this.awards = props.data.allStaffAward.edges;
		this.galleries = [];
		if (props.data.allStaffGallery && props.data.allStaffGallery.edges.length) this.galleries = props.data.allStaffGallery.edges;
		this.galleries.push({
			node: {
				title: "More Galleries", key: "more-galleries", galleryImages: [], links: [
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album&album_id=320026914734587", label: "2012 Safety Focus – Survivor" },
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album&album_id=1467564049980862", label: "2013 Safety Focus – Everyone Together Safe" },
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album&album_id=863416770395596", label: "2015 Safety Focus - Rockin’ the Oath" },
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album&album_id=1215735461830390", label: "2016 Safety Focus – The Decision Permian Basin" },
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album&album_id=1446072865463314", label: "2017 Safety Focus – S.M.A.R.T." },
					{ href: "https://www.facebook.com/pg/danos1947/photos/?tab=album", label: "2018 Employee Safety Focus - SPEAK" },
				]
			}
		})
		this.state = {
			team: null,
			open: false,
			members: null,
		}
		this.teamClick = this.teamClick.bind(this);
		this.dropDownHover = this.dropDownHover.bind(this);
		this.listEnter = this.listEnter.bind(this);
		this.listLeave = this.listLeave.bind(this);
	}
	componentDidMount() {
		let members = this.teams[0].node;
		members = members.Person;
		this.setState({
			team: this.teams[0].node.title,
			members: members,
		});
	}
	componentWillUnmount() {
		if (this.galleries.length && this.galleries[this.galleries.length - 1].node.key === "more-galleries") this.galleries.pop()
	}
	teamClick(index) {
		let members = this.teams[index].node;
		members = members.Person;
		this.setState({
			team: this.teams[index].node.title,
			open: false,
			members: members,
		});
	}
	dropDownHover() {
		this.setState({
			open: true,
		});
	}
	listEnter() {
		this.setState({
			open: true,
		})
	}
	listLeave() {
		this.setState({
			open: false,
		})
	}
	render() {
		return (
			<>
				<Layout image={this.page.headerImage} preTitle={this.page.pageTitle} title={this.page.subTitle} link={this.page.link} linkText={this.page.linkText} enableDanosFoundation={this.page.danosFoundation} enableFooterTabs={this.page.danosFooterTab}>
					<Intro pullQuote={this.page.pullQuote} headline={this.page.headline} pageCopy={this.page.pageCopy} />
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<hr style={{ margin: "60px auto" }} />
								<div className={style.teamSelector}>Our <span role="presentation" onMouseEnter={this.dropDownHover} onKeyUp={this.dropDownHover} className={style.teamSelectSelector}>{this.state.team}<Icon className={style.arrow} icon="chevronDown" /></span> Team
									<ul role="presentation" onMouseEnter={this.listEnter} onMouseLeave={this.listLeave} className={`${this.state.open ? style.open : ''} ${style.dropdown}`}>
										{this.teams.map((team, index) => (
											<li role="presentation" onClick={() => this.teamClick(index)} onKeyUp={() => this.teamClick(index)} key={index}>{team.node.title}</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<StaffGrid staff={this.state.members} teamName={this.state.team} />
					<section id="awards" className={style.awardsContainer}>
						<div className='grid-container'>
							<div className='grid-x grid-margin-x'>
								<div className='cell small-12 medium-8'>
									<h3 className={style.awardsHeader}>Awards</h3>
									<h4 className={style.awardsDescription}>Danos is proud to be publicly recognized as a leader in the oil and gas industry.</h4>
								</div>
							</div>
							<div className='grid-x grid-margin-x'>
								<div className='cell small-12'>
									{this.awards && this.awards.map((award, index) => {
										return (
											<Accordion key={index} title={award.node.title} body={award.node.description} />
										)
									})}
								</div>
							</div>
						</div>
					</section>
					{/* <section id="gallery">
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<hr style={{ margin: "0px auto 60px" }} />
									{ this.galleries.map((node, index) => (
										<GallerySlider data={[node]} left={index%2} />
									)) }
								</div>
							</div>
						</div>
					</section> */}
				</Layout>
				<SEO title="Our Team" />
			</>
		)
	}
}
