import React from "react";
import { graphql } from 'gatsby';

import StaffTeamAll from 'templates/StaffTeamAll';
const OurTeam = ({ data, pageContext }) => <StaffTeamAll data={data} />

export default OurTeam;

export const query = graphql`
	query ($lang: String) {
		allContentPage(filter: {uri: {eq: "/our-team/"}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					id
					uri
					content {
						main
					}
					lang
					pageTitle
					subTitle
					pullQuote
					pageCopy
					headline
					link
					linkText
					danosFoundation
					danosFooterTab
					headerImage
				}
			}
		}
		allStaffTeam(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					id
					internal {
					  content
					}
					Person {
						object {
							bio
							firstName
							email
							lastName
							photo
							position
							permalink
						}
					}
				}
			}
		}
		allStaffAward(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					description
				}
			}
		}
		allStaffGallery(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					galleryImages
				}
			}
		}
	}
`